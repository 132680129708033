.poweredBy {
    font-size: "10px";
    font-style: italic;
  }
  .successHeading {
    color: seagreen;
    font-size: 2rem;
  }
  .successPara {
    color: seagreen;
    font-size: 1rem;
  }
  
  .headerImg {
    width: 381px;
    height: 95px;
  }
  .paymentImage {
    max-width: 88px;
    max-height: 41px;
    cursor: pointer;
  }
  .mobilePaymentImage {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
  }
  .priceBoxWallet,
  .priceBoxWalletActive {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem;
    border-radius: 12px;
    cursor: pointer;
    height: 70px;
  }
  .priceBoxWallet:hover,
  .priceBoxWalletActive:hover {
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  }
  .priceBoxWallet {
    border: 1px solid #e3ddf3;
    color: #404040;
  }
  .priceBoxWalletActive {
    border: 1px solid #6631f7;
    color: #6631f7;
    background-color: #f0eaff;
  }
  .priceBox,
  .priceBoxActive {

    padding: 0.5rem;
    border-radius: 12px;
    cursor: pointer;
    height: 5.5rem;
  }
  .priceBox:hover,
  .priceBoxActive:hover {
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  }
  .priceBox {
    border: 1px solid #e3ddf3;
    color: #404040;
  }
  .priceBoxActive {
    border: 1px solid #6631f7;
    color: #6631f7;
    background-color: #f0eaff;
  }
  .mobilePriceBox,
  .mobilePriceBoxActive {
    font-size: 12px;
    padding: 0.2rem;
    border-radius: 12px;
    cursor: pointer;
    height: 2.5rem;
    width: 7rem;
  }
  .mobilePriceBox:hover,
  .mobilePriceBoxActive:hover {
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  }
  .mobilePriceBox {
    border: 1px solid #e3ddf3;
    color: #404040;
  }
  .mobilePriceBoxActive {
    border: 1px solid #6631f7;
    color: #6631f7;
    background-color: #f0eaff;
  }
  
  .inputField {
    border: none;
    width: 100%;
  }
  .inputField:focus {
    border: none;
    outline: none;
  }
  
  .emailInput {
    border: 1px solid #dddddd;
    border-radius: 7px;
    padding: 1rem;
  }
  .emailInput:focus {
    border: 1px solid #dddddd;
    border-radius: 7px;
    outline: none;
  }
  .emailInputError {
    border: 1px solid red;
    border-radius: 7px;
    padding: 1rem;
  }
  .primaryCta {
    border: 2px solid #6631f7;
    background-color: #6631f7;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding: 1rem;
    width: 100%;
    border-radius: 100px;
    cursor: pointer;
  }
  .primaryCta:hover {
    box-shadow: 0 0 0.5rem #d0c0ff;
  }
  .heading {
    color: #404040;
    font-size: 16px;
    font-weight: bold;
  }
  .labels {
    color: #313131;
    font-size: 12px;
    font-weight: bold;
  }
  
  @media screen and (max-width: 450px) {
    .heading {
      font-size: 10px;
      font-weight: 600;
    }
    .labels {
      font-size: 10px;
    }
  }
  