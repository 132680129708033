.svg {
  width: 80px;
  height: 80px;
  display: inline-block;
  opacity: 0.8;
}

h1 {
  text-align: center;
  margin: 0 0 30px;
  font-size: 34px;
  font-weight: 100;
  text-transform: uppercase;
  padding: 20px 0;
}
h1 b {
  font-weight: 700;
}
